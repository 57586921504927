import React from "react";

const Logo = () => (
    <svg width="250" viewBox="0 0 1542.06 154.21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#FFF" d="M1204.77,117.5h-75.44V86.4h68.16a11.91,11.91,0,1,0,0-23.82h-68.16V31.47h75.44a11.91,11.91,0,1,0,0-23.82h-88a11.86,11.86,0,0,0-11.92,11.8V130.74a11.86,11.86,0,0,0,11.81,11.91h88.11a11.86,11.86,0,0,0,11.91-11.81v-.1C1218,122.8,1212.71,117.5,1204.77,117.5Z"/>
        <path fill="#FFF" d="M205.21,142.57a67.18,67.18,0,0,1-67.14-67.14c0-36.74,29.77-67.78,67.14-67.78a67.18,67.18,0,0,1,67.14,67.14c0,38-29.77,67.78-67.14,67.78m0-111.48a44.34,44.34,0,1,0,44.34,44.34h0a44.47,44.47,0,0,0-44.34-44.34"/>
        <path fill="#FFF" d="M56.25,142.65C42.83,142.65,0,136.26,0,118.36a12.57,12.57,0,0,1,12.15-12.15,18.66,18.66,0,0,1,5.75,1.28c13.42,7,24.29,10.87,39,10.87,9,0,28.13-1.28,28.13-14.06C85,80,7,92.79,7,43.57,7,14.81,35.8,7.77,59.45,7.77c12.14,0,32,2.56,42.83,8.31C106.75,18,110,21.84,110,27A12.58,12.58,0,0,1,97.8,39.1a11.85,11.85,0,0,1-4.47-1.28C82.46,34,72.23,31.43,60.09,31.43c-8.31,0-26.85,0-26.85,12.14S64.56,62.75,73.51,65.3c19.82,5.76,37.71,14.07,37.71,37.72,0,31.32-28.76,39.63-55,39.63"/>
        <path fill="#FFF" d="M367.39,142.65a67.21,67.21,0,0,1-67.18-67.18C299.57,38.07,330,7.65,367.39,7.65A67.14,67.14,0,0,1,414.92,27.3a11.65,11.65,0,0,1-16.47,16.48,44.37,44.37,0,1,0-31.06,76.05,43.06,43.06,0,0,0,31.06-12.67,11.65,11.65,0,0,1,16.47,16.48,69.3,69.3,0,0,1-47.53,19"/>
        <path fill="#FFF" d="M467.43,7.65A11.28,11.28,0,0,0,456.1,18.9V131.31a11.28,11.28,0,0,0,11.24,11.34h.09c6.3,0,11.34-5.67,11.34-12V19A11.28,11.28,0,0,0,467.54,7.65Z"/>
        <path fill="#FFF" d="M788.34,142.85H701a11.44,11.44,0,0,1-11.48-11.4V19.13a11.5,11.5,0,1,1,23,0V119.89h75.89a11.43,11.43,0,0,1,11.48,11.38v.1c.64,6.38-5.1,11.48-11.48,11.48"/>
        <path fill="#FFF" d="M1006.49,142.65a11.28,11.28,0,0,1-10.13-6.33L940.63,24.86C937.47,19.16,940,12.2,945.7,9s12.66-.63,15.83,5.07l45.6,90.55,45.59-90.55c3.17-5.7,10.14-8.24,15.83-5.07s8.24,10.13,5.07,15.83l-57,110.82a10.85,10.85,0,0,1-10.14,7"/>
        <path fill="#FFF" d="M902,86.49H827a11.34,11.34,0,0,1,0-22.68h75a11.28,11.28,0,0,1,11.34,11.24v.1C914,80.82,909,86.49,902,86.49"/>
        <path fill="#FFF" d="M1474.21,142.65H1431a11.92,11.92,0,0,1-12-11.84V19.62a11.92,11.92,0,0,1,11.84-12h43.38a68.12,68.12,0,0,1,67.84,67.83c.66,36.58-29.93,67.17-67.84,67.17m-30.59-25.27h31.26a43.23,43.23,0,0,0,0-86.45h-31.26Z"/>
        <path fill="#FFF" d="M584.76,8a11.22,11.22,0,0,1,10.08,6.3l55.44,110.87c3.15,5.67.63,12.6-5,15.75s-12.6.63-15.75-5l-45.4-90.16-45.35,90.08c-3.15,5.67-10.08,8.19-15.75,5s-8.19-10.08-5-15.75l56.65-110.2A10.79,10.79,0,0,1,584.76,8"/>
        <path fill="#FFF" d="M1346.75,153.84a13,13,0,0,1-7.91-6.41l-46-99.23-19.2,74.45c-1.92,6.78-8.16,10.07-14.88,8.8-6.79-1.92-10.07-8.16-8.8-14.88L1276.92,9c1.48-4.76,5.7-8.49,10.26-9,5.21-.55,10.11,2.23,11.89,6.65L1345,106l19.19-74.45c1.27-6.71,8.17-10.07,14.88-8.8s10.08,8.16,8.81,14.88L1361,145.11c-1.47,4.76-5.69,8.49-10.25,9a8.34,8.34,0,0,1-4-.24"/>
    </svg>
);

export default Logo;
