import React from "react"
import { Link, graphql } from "gatsby"
import { RichText } from 'prismic-reactjs';
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import Img from "gatsby-image"

import homeStyle from './index.module.css'

import Layout from "../components/layout"
import SVLogo from '../components/_ui/SVLogo'

const RenderBody = ({ home, meta }) => (
  <>
      <Helmet
          title={meta.title}
          titleTemplate={`%s | ${meta.title}`}
          meta={[
              {
                  name: `description`,
                  content: meta.description,
              },
              {
                  property: `og:title`,
                  content: meta.title,
              },
              {
                  property: `og:description`,
                  content: meta.description,
              },
              {
                  property: `og:type`,
                  content: `website`,
              },
              {
                  name: `twitter:card`,
                  content: `summary`,
              },
              {
                  name: `twitter:creator`,
                  content: meta.author,
              },
              {
                  name: `twitter:title`,
                  content: meta.title,
              },
              {
                  name: `twitter:description`,
                  content: meta.description,
              },
          ].concat(meta)}
      />
      <div>
        <div className={'row ' + homeStyle.heroText}>
          {RichText.render(home.welcome)}
        </div>

        <div className={'row ' + homeStyle.features}>
          {/* Image */}
          <div className={homeStyle.featuresInner}>
            {RichText.render(home.mothershiptext)}
          </div>
          <div className={homeStyle.featuresInner} style={{ display: 'contents' }}>
            <Img fluid={home.mothershipimageSharp.childImageSharp.fluid} />
          </div>
        </div>

        <div className={'row row--alt ' + homeStyle.features}>
          <div className={homeStyle.featuresInner}>
            {RichText.render(home.fieldcommandtext)}
          </div>
          <div className={homeStyle.featuresInner} style={{ display: 'contents' }}>
            <Img fluid={home.fieldcommandimageSharp.childImageSharp.fluid} />
          </div>
        </div>

        <div className={'row ' + homeStyle.about}>
          <div>
            {RichText.render(home.about)}
          </div>
          <div>
            <a href="https://socialvend.com" >
              <SVLogo />
            </a>
          </div>
        </div>

      </div>
  </>
);

export default ({ data }) => {
  //Required check for no data being returned
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop();
  // const meta = data.prismic.siteMetadata.edges;

  const meta = {
    title: `vendOS | The worlds first platform for smart vending machines`,
    description: `Welcome to the worlds first smart vending platform, made up of two core components; Mothership & FieldCommand.`,
    author: `@socialvend`,
  }

  if (!doc) return null;

  return (
      <Layout>
          <RenderBody home={doc.node} meta={meta}/>
      </Layout>
  )
}

RenderBody.propTypes = {
  home: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

export const query = graphql`
{
  prismic {
    allHomepages {
      edges {
        node {
          title,
          welcome,
          mothershiptext,
          mothershipimage,
          mothershipimageSharp {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          fieldcommandtext,
          fieldcommandimage,
          fieldcommandimageSharp {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          },
          about,
        }
      }
    }
  }
}
`